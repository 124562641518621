import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaCreditCard, FaCalendarAlt, FaChartPie, FaBell } from 'react-icons/fa';
import { fetchSubscriptions } from '../features/subscription/subscriptionSlice';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { subscriptions, status, error } = useSelector((state) => state.subscriptions);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchSubscriptions());
    }
  }, [status, dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
  };

  const totalMonthlySubscriptions = subscriptions.reduce((sum, sub) => sum + sub.amount, 0);

  const subscriptionCategories = subscriptions.reduce((acc, sub) => {
    acc[sub.category] = (acc[sub.category] || 0) + sub.amount;
    return acc;
  }, {});

  const sortedSubscriptions = [...subscriptions].sort((a, b) => new Date(a.nextBillingDate) - new Date(b.nextBillingDate));

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Welcome, {user?.firstName}!</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaCreditCard className="mr-2 text-blue-500" />
            Monthly Subscriptions
          </h2>
          <p className="text-3xl font-bold text-blue-600">${totalMonthlySubscriptions.toFixed(2)}</p>
        </div>

        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaCalendarAlt className="mr-2 text-green-500" />
            Next Payment Due
          </h2>
          {sortedSubscriptions.length > 0 ? (
            <>
              <p className="text-xl font-semibold">{sortedSubscriptions[0].name}</p>
              <p className="text-gray-600">On {formatDate(sortedSubscriptions[0].nextBillingDate)}</p>
            </>
          ) : (
            <p>No upcoming payments</p>
          )}
        </div>

        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaChartPie className="mr-2 text-purple-500" />
            Subscription Categories
          </h2>
          <ul>
            {Object.entries(subscriptionCategories).map(([category, amount], index) => (
              <li key={index} className="flex justify-between items-center mb-2">
                <span>{category}</span>
                <span className="font-semibold">${amount.toFixed(2)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {subscriptions.length === 0 ? (
        <div className="text-center">
          <p className="mb-4">You have no subscriptions.</p>
          <button
            onClick={() => navigate('/subscriptions')}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            Add Subscription
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <FaCreditCard className="mr-2 text-blue-500" />
              Active Subscriptions
            </h2>
            <ul>
              {sortedSubscriptions.map(subscription => (
                <li key={subscription._id} className="mb-4 pb-4 border-b last:border-b-0">
                  <div className="flex justify-between items-center">
                    <span className="font-semibold">{subscription.name}</span>
                    <span className="font-bold text-red-600">${subscription.amount.toFixed(2)}</span>
                  </div>
                  <span className="text-sm text-gray-500">Next bill on {formatDate(subscription.nextBillingDate)}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <FaBell className="mr-2 text-yellow-500" />
              Upcoming Reminders
            </h2>
            <ul>
              {sortedSubscriptions.slice(0, 3).map(subscription => (
                <li key={subscription._id} className="mb-4 pb-4 border-b last:border-b-0">
                  <div className="flex justify-between items-center">
                    <span className="font-semibold">{subscription.name}</span>
                    <span className="font-bold text-red-600">${subscription.amount.toFixed(2)}</span>
                  </div>
                  <span className="text-sm text-gray-500">Due on {formatDate(subscription.nextBillingDate)}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;