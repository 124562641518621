import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { login } from '../features/auth/authSlice';
import { clearError, setNeedsVerification } from '../features/auth/authSlice';

const InputField = ({ icon, type, placeholder, value, onChange, toggleShowPassword }) => (
    <div className="flex items-center bg-white border rounded-lg px-3 py-2 mb-4">
        <span className="text-gray-400 mr-2">{icon}</span>
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className="flex-grow outline-none bg-transparent"
        />
        {toggleShowPassword && (
            <span className="cursor-pointer text-gray-400 ml-2" onClick={toggleShowPassword}>
                {type === 'password' ? <FaEye /> : <FaEyeSlash />}
            </span>
        )}
    </div>
);

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, loading, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
        return () => {
            dispatch(clearError());
        };
    }, [isAuthenticated, navigate, dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await dispatch(login({ email, password }));
        if (login.fulfilled.match(result) && result.payload.user.isVerified) {
            navigate('/dashboard');
        } else if (login.rejected.match(result) && result.payload === 'Email not verified') {
            dispatch(setNeedsVerification(email));
            navigate('/verify');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Sign in to your account
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Or{' '}
                        <Link to="/register" className="font-medium text-primary hover:text-primary-dark">
                            create a new account
                        </Link>
                    </p>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <InputField
                        icon={<FaEnvelope />}
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputField
                        icon={<FaLock />}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        toggleShowPassword={() => setShowPassword(!showPassword)}
                    />
                    <div className="flex items-center justify-end">
                        <div className="text-sm">
                            <Link to="/forgot-password" className="font-medium text-primary hover:text-primary-dark">
                                Forgot your password?
                            </Link>
                        </div>
                    </div>
                    {error && <div className="text-red-500 text-center">{error}</div>}
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            disabled={loading}
                        >
                            {loading ? 'Signing in...' : 'Sign In'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;