import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail, loadUser } from '../features/auth/authSlice';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const EmailVerification = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { error, isAuthenticated, user } = useSelector((state) => state.auth);
    const [verificationStatus, setVerificationStatus] = useState('verifying');
    const verificationAttempted = useRef(false);

    useEffect(() => {
        const verifyToken = async () => {
            if (verificationAttempted.current) {
                // console.log("Verification already attempted");
                return;
            }

            verificationAttempted.current = true;
            setVerificationStatus('verifying');
            // console.log("Starting email verification with token:", token);

            try {
                const resultAction = await dispatch(verifyEmail(token));
                // console.log("Verification result:", resultAction);

                if (verifyEmail.fulfilled.match(resultAction)) {
                    setVerificationStatus('success');
                    // console.log("Verification successful, loading user data");
                    await dispatch(loadUser());
                } else {
                    setVerificationStatus('error');
                    // console.error("Verification failed:", resultAction.error);
                }
            } catch (err) {
                console.error("Unexpected error during verification:", err);
                setVerificationStatus('error');
            }
        };

        verifyToken();
    }, [token, dispatch]);

    useEffect(() => {
        if (isAuthenticated && user) {
            console.log("User authenticated and loaded, redirecting to dashboard");
            navigate('/dashboard');
        }
    }, [isAuthenticated, user, navigate]);

    const renderContent = () => {
        switch (verificationStatus) {
            case 'verifying':
                return (
                    <div className="animate-pulse flex flex-col items-center">
                        <div className="w-20 h-20 bg-primary rounded-full mb-4"></div>
                        <h2 className="text-2xl font-semibold text-gray-700">Verifying your email...</h2>
                    </div>
                );
            case 'success':
                return (
                    <div className="flex flex-col items-center">
                        <FaCheckCircle className="text-green-500 text-6xl mb-4" />
                        <h2 className="text-2xl font-semibold text-gray-700">Email Verified Successfully!</h2>
                        <p className="mt-2 text-gray-600">Redirecting to dashboard...</p>
                    </div>
                );
            case 'error':
                return (
                    <div className="flex flex-col items-center">
                        <FaTimesCircle className="text-red-500 text-6xl mb-4" />
                        <h2 className="text-2xl font-semibold text-gray-700">Verification Failed</h2>
                        <p className="mt-2 text-gray-600">{error}</p>
                        <button
                            onClick={() => navigate('/login')}
                            className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
                        >
                            Go to Login
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                {renderContent()}
            </div>
        </div>
    );
};

export default EmailVerification;