import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaCaretDown } from 'react-icons/fa';
import { logout } from '../features/auth/authSlice';

const Header = ({ isAuthenticated }) => {
    const { user } = useSelector((state) => state.auth);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleLogout = () => {
        if (window.confirm("Are you sure you want to logout?")) {
            dispatch(logout());
            navigate('/');
            setDropdownOpen(false);  // Close the dropdown after logout
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className={`bg-white shadow-md ${isAuthenticated ? 'hidden lg:block' : ''}`}>
            <div className="flex justify-between items-center py-4 px-4 sm:px-6 lg:px-8">
                {!isAuthenticated && (
                    <Link to="/" className="text-2xl font-bold text-primary">
                        TracKxpense
                    </Link>
                )}
                <nav className={`flex items-center space-x-6 pr-4 ${isAuthenticated ? 'ml-auto' : ''}`}>
                    {isAuthenticated ? (
                        <div className="relative" ref={dropdownRef}>
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-2 text-gray-700 hover:text-primary focus:outline-none"
                            >
                                <div className="w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                                    <FaUser className="text-white" />
                                </div>
                                <span className="font-medium">{user?.firstName}</span>
                                <FaCaretDown className={`transform transition-transform duration-200 ${dropdownOpen ? 'rotate-180' : ''}`} />
                            </button>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                                    <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Settings</Link>
                                    <button
                                        onClick={handleLogout}
                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <Link to="/login" className="text-primary hover:text-primary-dark font-medium transition duration-300">
                                Login
                            </Link>
                            <Link to="/register" className="bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark transition duration-300">
                                Sign Up
                            </Link>
                        </>
                    )}
                </nav>
            </div>
        </header>
    );
};

export default Header;