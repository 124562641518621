import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

const getAuthToken = () => {
    return localStorage.getItem('token');
};

export const fetchSubscriptions = createAsyncThunk(
    'subscriptions/fetchSubscriptions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${config.API_URL}/api/subscriptions`, {
                headers: { 'x-auth-token': getAuthToken() }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addSubscription = createAsyncThunk(
    'subscriptions/addSubscription',
    async (subscriptionData, { rejectWithValue }) => {
        try {
            const dataToSend = {
                ...subscriptionData,
                amount: parseFloat(subscriptionData.amount)
            };
            const response = await axios.post(`${config.API_URL}/api/subscriptions`, dataToSend, {
                headers: { 'x-auth-token': getAuthToken() }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateSubscription = createAsyncThunk(
    'subscriptions/updateSubscription',
    async ({ id, subscriptionData }, { rejectWithValue }) => {
        try {
            const dataToSend = {
                ...subscriptionData,
                amount: parseFloat(subscriptionData.amount)
            };
            const response = await axios.put(`${config.API_URL}/api/subscriptions/${id}`, dataToSend, {
                headers: { 'x-auth-token': getAuthToken() }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteSubscription = createAsyncThunk(
    'subscriptions/deleteSubscription',
    async (id, { rejectWithValue }) => {
        try {
            await axios.delete(`${config.API_URL}/api/subscriptions/${id}`, {
                headers: { 'x-auth-token': getAuthToken() }
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState: {
        subscriptions: [],
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subscriptions = action.payload;
            })
            .addCase(fetchSubscriptions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(addSubscription.fulfilled, (state, action) => {
                state.subscriptions.push(action.payload);
            })
            .addCase(updateSubscription.fulfilled, (state, action) => {
                const index = state.subscriptions.findIndex(sub => sub._id === action.payload._id);
                if (index !== -1) {
                    state.subscriptions[index] = action.payload;
                }
            })
            .addCase(deleteSubscription.fulfilled, (state, action) => {
                state.subscriptions = state.subscriptions.filter(sub => sub._id !== action.payload);
            });
    },
});

export default subscriptionSlice.reducer;