import React from 'react';

const PrivacyPolicy = () => {
    const date = new Date().getFullYear();
    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <p className="mb-4">Last updated: {date}</p>
            <p className="mb-4">
                At TracKxpense, we take your privacy seriously. This Privacy Policy describes how we collect, use, and protect your personal information.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Information We Collect</h2>
            <p className="mb-4">
                We collect information you provide directly to us, such as when you create an account, link your financial accounts, or contact us for support.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">How We Use Your Information</h2>
            <p className="mb-4">
                We use your information to provide, maintain, and improve our services, as well as to communicate with you about your account and our services.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Data Security</h2>
            <p className="mb-4">
                We implement a variety of security measures to maintain the safety of your personal information.
            </p>
            {/* Add more sections as needed */}
        </div>
    );
};

export default PrivacyPolicy;