import React from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaListUl, FaBell } from 'react-icons/fa';

const FeatureCard = ({ icon, title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
        <div className="text-4xl text-primary mb-4">{icon}</div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

const LandingPage = () => {
    return (
        <div className="bg-gradient-to-b from-gray-50 to-white">
            <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
                <div className="text-center">
                    <h1 className="text-5xl font-extrabold text-gray-900 mb-4">
                        Manage Your Subscriptions
                    </h1>
                    <p className="text-xl text-gray-600 mb-8">
                        TracKxpense helps you manage all your subscriptions in one place.
                        Get started for free and take control of your recurring expenses.
                    </p>
                    <Link
                        to="/login"
                        className="bg-primary text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-primary-dark transition-colors duration-300"
                    >
                        Get Started For Free
                    </Link>
                </div>

                <div className="flex justify-center">
                    <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<FaChartLine />}
                            title="Smart Tracking"
                            description="Manually input and categorize your subscriptions for easy management."
                        />
                        <FeatureCard
                            icon={<FaListUl />}
                            title="Subscription Overview"
                            description="Get a clear view of all your subscriptions in one centralized dashboard."
                        />
                        <FeatureCard
                            icon={<FaBell />}
                            title="Timely Reminders"
                            description="Never miss a payment or get surprised by auto-renewals again."
                        />
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="text-center">
                            <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                                <span className="text-2xl font-bold text-primary">1</span>
                            </div>
                            <h3 className="font-semibold mb-2">Add Your Subscriptions</h3>
                            <p className="text-gray-600">Manually input your subscription details into the app.</p>
                        </div>
                        <div className="text-center">
                            <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                                <span className="text-2xl font-bold text-primary">2</span>
                            </div>
                            <h3 className="font-semibold mb-2">Automatic Detection</h3>
                            <p className="text-gray-600">We'll identify and categorize all your recurring subscriptions.</p>
                        </div>
                        <div className="text-center">
                            <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                                <span className="text-2xl font-bold text-primary">3</span>
                            </div>
                            <h3 className="font-semibold mb-2">Stay Informed</h3>
                            <p className="text-gray-600">Receive reminders and manage your subscriptions effortlessly.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center">
                <h2 className="text-3xl font-bold mb-4">Ready to Simplify Your Subscriptions?</h2>
                <p className="text-xl text-gray-600 mb-8">
                    Join now and get access to all features for free. Limited time offer!
                </p>
                <Link
                    to="/login"
                    className="bg-primary text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-primary-dark transition-colors duration-300"
                >
                    Get Started - It's Free!
                </Link>
            </section>
        </div>
    );
};

export default LandingPage;