import React from 'react';

const TermsOfService = () => {
    const date = new Date().getFullYear();
    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
            <p className="mb-4">Last updated: {date}</p>
            <p className="mb-4">
                Welcome to TracKxpense. By using our service, you agree to these terms. Please read them carefully.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Use of Service</h2>
            <p className="mb-4">
                You must follow any policies made available to you within the Services. You may use our Services only as permitted by law. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Your TracKxpense Account</h2>
            <p className="mb-4">
                You need a TracKxpense Account to use our Services. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Privacy and Copyright Protection</h2>
            <p className="mb-4">
                TracKxpense's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that TracKxpense can use such data in accordance with our privacy policies.
            </p>
            {/* Add more sections as needed */}
        </div>
    );
};

export default TermsOfService;