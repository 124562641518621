import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaBell, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { updateProfile, deleteAccount, changePassword } from '../features/auth/authSlice';
import { fetchReminderSettings, updateReminderSettings } from '../features/reminders/remindersSlice';

const Settings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, loading, error } = useSelector((state) => state.auth);
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    const [passwordError, setPasswordError] = useState('');

    const [showPasswords, setShowPasswords] = useState({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false
    });

    const { settings: reminderSettings, status: reminderStatus } = useSelector((state) => state.reminders);


    const togglePasswordVisibility = (field) => {
        setShowPasswords(prev => ({ ...prev, [field]: !prev[field] }));
    };

    useEffect(() => {
        if (user) {
            setProfile({
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email || ''
            });
        }
    }, [user]);


    const handleProfileChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    };

    const handleProfileSubmit = async (e) => {
        e.preventDefault();
        const confirm = window.confirm('Are you sure you want to save these changes?');
        if (confirm) {
            const result = await dispatch(updateProfile({ firstName: profile.firstName, lastName: profile.lastName }));
            if (!result.error) {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 3000);
            }
        }
    };

    useEffect(() => {
        dispatch(fetchReminderSettings());
    }, [dispatch]);

    const handleReminderToggle = () => {
        dispatch(updateReminderSettings({ ...reminderSettings, enabled: !reminderSettings.enabled }));
    };

    const handleReminderDaysChange = (e) => {
        dispatch(updateReminderSettings({ ...reminderSettings, daysBeforeRenewal: parseInt(e.target.value) }));
    };

    const handleDeleteAccount = async () => {
        const confirm = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
        if (confirm) {
            const result = await dispatch(deleteAccount());
            if (!result.error) {
                navigate('/');
            }
        }
    };

    const handlePasswordChange = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    };

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();
        setPasswordError('');

        if (passwordData.newPassword !== passwordData.confirmNewPassword) {
            setPasswordError("New passwords don't match");
            return;
        }

        const result = await dispatch(changePassword({
            currentPassword: passwordData.currentPassword,
            newPassword: passwordData.newPassword
        }));

        if (!result.error) {
            setShowChangePasswordModal(false);
            setPasswordData({
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            });
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 3000);
        } else {
            setPasswordError(result.payload);
            setTimeout(() => {
                setPasswordError('');
            }, 3000);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Account Settings</h1>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Profile Information */}
                <div className="md:col-span-2 bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4 flex items-center">
                        <FaUser className="mr-2 text-blue-500" />
                        Profile Information
                    </h2>
                    {showSuccessAlert && <div className="mb-4 text-green-500">Changes saved successfully!</div>}
                    <form onSubmit={handleProfileSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="firstName">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={profile.firstName}
                                    onChange={handleProfileChange}
                                    className="w-full px-3 py-2 border rounded-md"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="lastName">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={profile.lastName}
                                    onChange={handleProfileChange}
                                    className="w-full px-3 py-2 border rounded-md"
                                    required
                                />
                            </div>
                            <div className="md:col-span-2">
                                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    disabled
                                    value={profile.email}
                                    className="w-full px-3 py-2 border rounded-md bg-gray-100"
                                    required
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </form>
                </div>

                {/* Reminder Settings */}
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4 flex items-center">
                        <FaBell className="mr-2 text-yellow-500" />
                        Reminder Settings
                    </h2>
                    <div className="flex items-center justify-between mb-4">
                        <span>Enable Reminders</span>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                checked={reminderSettings.enabled}
                                onChange={handleReminderToggle}
                                disabled={reminderStatus === 'loading'}
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="reminderDays">
                            Remind me before
                        </label>
                        <select
                            id="reminderDays"
                            value={reminderSettings.daysBeforeRenewal}
                            onChange={handleReminderDaysChange}
                            className="w-full px-3 py-2 border rounded-md"
                            disabled={!reminderSettings.enabled || reminderStatus === 'loading'}
                        >
                            <option value={1}>1 day</option>
                            <option value={3}>3 days</option>
                            <option value={7}>7 days</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Security Settings */}
            <div className="mt-6 bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4 flex items-center">
                    <FaLock className="mr-2 text-red-500" />
                    Security
                </h2>
                <div className="space-y-4 flex flex-col items-start">
                    <button
                        className="bg-gray-100 text-gray-800 px-4 py-2 rounded hover:bg-gray-200 transition-colors w-auto"
                        onClick={() => setShowChangePasswordModal(true)}
                    >
                        Change Password
                    </button>
                    <button
                        className="bg-red-100 text-red-800 px-4 py-2 rounded hover:bg-red-200 transition-colors w-auto"
                        onClick={handleDeleteAccount}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Delete Account'}
                    </button>
                </div>
            </div>

            {/* Change Password Modal */}
            {showChangePasswordModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <form onSubmit={handleChangePasswordSubmit}>
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Change Password</h3>
                                    <div className="mb-4 relative">
                                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="currentPassword">
                                            Current Password
                                        </label>
                                        <input
                                            type={showPasswords.currentPassword ? "text" : "password"}
                                            id="currentPassword"
                                            name="currentPassword"
                                            value={passwordData.currentPassword}
                                            onChange={handlePasswordChange}
                                            className="w-full px-3 py-2 border rounded-md pr-10"
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-6"
                                            onClick={() => togglePasswordVisibility('currentPassword')}
                                        >
                                            {showPasswords.currentPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                    <div className="mb-4 relative">
                                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="newPassword">
                                            New Password
                                        </label>
                                        <input
                                            type={showPasswords.newPassword ? "text" : "password"}
                                            id="newPassword"
                                            name="newPassword"
                                            value={passwordData.newPassword}
                                            onChange={handlePasswordChange}
                                            className="w-full px-3 py-2 border rounded-md pr-10"
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-6"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                        >
                                            {showPasswords.newPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                    <div className="mb-4 relative">
                                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="confirmNewPassword">
                                            Confirm New Password
                                        </label>
                                        <input
                                            type={showPasswords.confirmNewPassword ? "text" : "password"}
                                            id="confirmNewPassword"
                                            name="confirmNewPassword"
                                            value={passwordData.confirmNewPassword}
                                            onChange={handlePasswordChange}
                                            className="w-full px-3 py-2 border rounded-md pr-10"
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-6"
                                            onClick={() => togglePasswordVisibility('confirmNewPassword')}
                                        >
                                            {showPasswords.confirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                    {passwordError && <p className="text-red-500 mb-4">{passwordError}</p>}
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="submit"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        disabled={loading}
                                    >
                                        {loading ? 'Changing...' : 'Change Password'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setShowChangePasswordModal(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;