import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resendVerification } from '../features/auth/authSlice';

const VerificationPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, loading, error } = useSelector((state) => state.auth);
    const [resendStatus, setResendStatus] = useState('');
    const effectRan = useRef(false);

    useEffect(() => {
        if (effectRan.current === false) {
            if (user && user.email) {
                handleResendVerification();
            } else {
                setResendStatus('No email address found. Please try logging in again.');
            }
            return () => {
                effectRan.current = true;
            };
        }
    }, [user]);

    const handleResendVerification = () => {
        if (user && user.email) {
            dispatch(resendVerification(user.email))
                .unwrap()
                .then(() => setResendStatus('Verification email sent successfully!'))
                .catch((error) => setResendStatus(`Error: ${error}`));
        } else {
            setResendStatus('No email address found. Please try logging in again.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Verify Your Email
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    We've sent a verification email to {user?.email}. Please check your inbox and click the verification link.
                </p>
                {error && <p className="text-red-500 text-center">{error}</p>}
                {resendStatus && <p className="text-blue-500 text-center">{resendStatus}</p>}
                <button
                    onClick={handleResendVerification}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    disabled={loading}
                >
                    {loading ? 'Sending...' : 'Resend Verification Email'}
                </button>
                <button
                    onClick={() => navigate('/login')}
                    className="mt-4 w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                    Back to Login
                </button>
            </div>
        </div>
    );
};

export default VerificationPage;