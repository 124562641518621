import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaPlus, FaEdit, FaTrash, FaFilter } from 'react-icons/fa';
import { fetchSubscriptions, addSubscription, updateSubscription, deleteSubscription } from '../features/subscription/subscriptionSlice';

const Subscriptions = () => {
    const dispatch = useDispatch();
    const { subscriptions, status, error } = useSelector((state) => state.subscriptions);
    const [showModal, setShowModal] = useState(false);
    const [editingSubscription, setEditingSubscription] = useState(null);
    const [newSubscription, setNewSubscription] = useState({
        name: '',
        amount: '',
        billingCycle: 'Monthly',
        category: 'Entertainment',
        nextBillingDate: ''
    });
    const [filterCategory, setFilterCategory] = useState('');

    const categories = ['Entertainment', 'Utilities', 'Food & Drink', 'Shopping', 'Software', 'Other'];

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchSubscriptions());
        }
    }, [status, dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (editingSubscription) {
            setEditingSubscription({ ...editingSubscription, [name]: value });
        } else {
            setNewSubscription({ ...newSubscription, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editingSubscription) {
            dispatch(updateSubscription({
                id: editingSubscription._id,
                subscriptionData: {
                    ...editingSubscription,
                    nextBillingDate: formatDateForBackend(editingSubscription.nextBillingDate)
                }
            }));
            setEditingSubscription(null);
        } else {
            dispatch(addSubscription({
                ...newSubscription,
                nextBillingDate: formatDateForBackend(newSubscription.nextBillingDate)
            }));
        }
        setShowModal(false);
        setNewSubscription({
            name: '',
            amount: '',
            billingCycle: 'Monthly',
            category: 'Entertainment',
            nextBillingDate: ''
        });
    };

    const handleEdit = (subscription) => {
        setEditingSubscription({
            ...subscription,
            nextBillingDate: formatDateForInput(subscription.nextBillingDate)
        });
        setShowModal(true);
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this subscription?')) {
            dispatch(deleteSubscription(id));
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC'
        });
    };

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const formatDateForBackend = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString();
    };

    const filteredSubscriptions = filterCategory
        ? subscriptions.filter(sub => sub.category === filterCategory)
        : subscriptions;

    const sortedSubscriptions = [...filteredSubscriptions].sort((a, b) => 
        new Date(a.nextBillingDate) - new Date(b.nextBillingDate)
    );

    const totalSubscriptions = sortedSubscriptions.length;
    const monthlySpend = sortedSubscriptions.reduce((total, sub) => {
        return total + (sub.billingCycle === 'Monthly' ? parseFloat(sub.amount) : parseFloat(sub.amount) / 12);
    }, 0);
    const yearlySpend = monthlySpend * 12;

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="container mx-auto px-4">
            <h1 className="text-2xl font-bold mb-4">Subscriptions</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                <div className="bg-white p-4 rounded shadow">
                    <h2 className="text-lg font-semibold">Total Subscriptions</h2>
                    <p className="text-2xl font-bold">{totalSubscriptions}</p>
                </div>
                <div className="bg-white p-4 rounded shadow">
                    <h2 className="text-lg font-semibold">Monthly Spend</h2>
                    <p className="text-2xl font-bold">${monthlySpend.toFixed(2)}</p>
                </div>
                <div className="bg-white p-4 rounded shadow">
                    <h2 className="text-lg font-semibold">Yearly Spend</h2>
                    <p className="text-2xl font-bold">${yearlySpend.toFixed(2)}</p>
                </div>
            </div>
            <div className="mb-4 flex justify-between items-center">
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus className="inline-block mr-2" /> Add New Subscription
                </button>
                <div className="flex items-center">
                    <FaFilter className="mr-2" />
                    <select
                        className="border rounded px-2 py-1"
                        value={filterCategory}
                        onChange={(e) => setFilterCategory(e.target.value)}
                    >
                        <option value="">All Categories</option>
                        {categories.map((category) => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full bg-white shadow-md rounded mb-4">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">Name</th>
                            <th className="py-3 px-6 text-left">Amount</th>
                            <th className="py-3 px-6 text-center">Billing Cycle</th>
                            <th className="py-3 px-6 text-center">Category</th>
                            <th className="py-3 px-6 text-center">Next Billing Date</th>
                            <th className="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {sortedSubscriptions.map((subscription) => (
                            <tr key={subscription._id} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    <div className="flex items-center">
                                        <span className="font-medium">{subscription.name}</span>
                                    </div>
                                </td>
                                <td className="py-3 px-6 text-left">
                                    <span>${parseFloat(subscription.amount).toFixed(2)}</span>
                                </td>
                                <td className="py-3 px-6 text-center">
                                    <span>{subscription.billingCycle}</span>
                                </td>
                                <td className="py-3 px-6 text-center">
                                    <span>{subscription.category}</span>
                                </td>
                                <td className="py-3 px-6 text-center">
                                    <span>{formatDate(subscription.nextBillingDate)}</span>
                                </td>
                                <td className="py-3 px-6 text-center">
                                    <div className="flex item-center justify-center">
                                        <button
                                            className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                            onClick={() => handleEdit(subscription)}
                                        >
                                            <FaEdit />
                                        </button>
                                        <button
                                            className="w-4 mr-2 transform hover:text-red-500 hover:scale-110"
                                            onClick={() => handleDelete(subscription._id)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <form onSubmit={handleSubmit}>
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                            Name
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="name"
                                            type="text"
                                            placeholder="Subscription name"
                                            name="name"
                                            value={editingSubscription ? editingSubscription.name : newSubscription.name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
                                            Amount
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="amount"
                                            type="number"
                                            step="0.01"
                                            placeholder="Amount"
                                            name="amount"
                                            value={editingSubscription ? editingSubscription.amount : newSubscription.amount}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="billingCycle">
                                            Billing Cycle
                                        </label>
                                        <select
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="billingCycle"
                                            name="billingCycle"
                                            value={editingSubscription ? editingSubscription.billingCycle : newSubscription.billingCycle}
                                            onChange={handleInputChange}
                                        >
                                            <option value="Monthly">Monthly</option>
                                            <option value="Annually">Annually</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                                            Category
                                        </label>
                                        <select
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="category"
                                            name="category"
                                            value={editingSubscription ? editingSubscription.category : newSubscription.category}
                                            onChange={handleInputChange}
                                        >
                                            {categories.map((category) => (
                                                <option key={category} value={category}>{category}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nextBillingDate">
                                            Next Billing Date
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="nextBillingDate"
                                            type="date"
                                            name="nextBillingDate"
                                            value={editingSubscription ? editingSubscription.nextBillingDate : newSubscription.nextBillingDate}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="submit"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        {editingSubscription ? 'Update Subscription' : 'Add Subscription'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            setShowModal(false);
                                            setEditingSubscription(null);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Subscriptions;