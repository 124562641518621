import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

const initialState = {
    settings: {
        enabled: false,
        daysBeforeRenewal: 3
    },
    status: 'idle',
    error: null
};

export const fetchReminderSettings = createAsyncThunk(
    'reminders/fetchSettings',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${config.API_URL}/api/reminders/settings`); return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const updateReminderSettings = createAsyncThunk(
    'reminders/updateSettings',
    async (settings, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${config.API_URL}/api/reminders/settings`, settings); return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

const remindersSlice = createSlice({
    name: 'reminders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReminderSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReminderSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.settings = action.payload;
            })
            .addCase(fetchReminderSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateReminderSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateReminderSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.settings = action.payload;
            })
            .addCase(updateReminderSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default remindersSlice.reducer;