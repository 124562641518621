import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: localStorage.getItem('token') ? true : false,
    loading: false,
    user: null,
    error: null,
    needsVerification: false,
    isBankConnected: false,
};

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['x-auth-token'] = token;
    } else {
        delete axios.defaults.headers.common['x-auth-token'];
    }
};

export const register = createAsyncThunk(
    'auth/register',
    async ({ firstName, lastName, email, password }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${config.API_URL}/api/auth/register`, { firstName, lastName, email, password });
            localStorage.setItem('token', res.data.token);
            setAuthToken(res.data.token);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const login = createAsyncThunk(
    'auth/login',
    async ({ email, password }, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.post(`${config.API_URL}/api/auth/login`, { email, password });
            if (res.data.user.isVerified) {
                localStorage.setItem('token', res.data.token);
                setAuthToken(res.data.token);
                return res.data;
            } else {
                dispatch(setNeedsVerification(email));
                return rejectWithValue('Email not verified');
            }
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const verifyEmail = createAsyncThunk(
    'auth/verifyEmail',
    async (token, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${config.API_URL}/api/auth/verify-email/${token}`);
            localStorage.setItem('token', res.data.token);
            setAuthToken(res.data.token);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const loadUser = createAsyncThunk(
    'auth/loadUser',
    async (_, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found');
            }
            setAuthToken(token);
            const res = await axios.get(`${config.API_URL}/api/auth/user`);
            return res.data;
        } catch (err) {
            localStorage.removeItem('token');
            setAuthToken(null);
            return rejectWithValue(err.response ? err.response.data.msg : err.message);
        }
    }
);

export const resendVerification = createAsyncThunk(
    'auth/resendVerification',
    async (email, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${config.API_URL}/api/auth/resend-verification`, { email });
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (email, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${config.API_URL}/api/auth/forgot-password`, { email });
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ token, password }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${config.API_URL}/api/auth/reset-password/${token}`, { password });
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const updateProfile = createAsyncThunk(
    'auth/updateProfile',
    async ({ firstName, lastName }, { getState, rejectWithValue }) => {
        try {
            const token = getState().auth.token;
            setAuthToken(token);
            const res = await axios.put(`${config.API_URL}/api/auth/update-profile`, { firstName, lastName });
            return res.data.user;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const deleteAccount = createAsyncThunk(
    'auth/deleteAccount',
    async (_, { getState, rejectWithValue }) => {
        try {
            const token = getState().auth.token;
            setAuthToken(token);
            await axios.delete(`${config.API_URL}/api/auth/delete-account`);
            localStorage.removeItem('token');
            return { msg: 'Account deleted successfully' };
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async ({ currentPassword, newPassword }, { getState, rejectWithValue }) => {
        try {
            const token = getState().auth.token;
            setAuthToken(token);
            const res = await axios.put(`${config.API_URL}/api/auth/change-password`, { currentPassword, newPassword });
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data.msg);
        }
    }
);



const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('token');
            setAuthToken(null);
            state.token = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.user = null;
            state.error = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        setNeedsVerification: (state, action) => {
            state.needsVerification = true;
            state.user = { email: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.loading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.loading = false;
                state.needsVerification = true;
                state.user = { email: action.payload.user.email };
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.token = action.payload.token;
                state.user = action.payload.user;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(verifyEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.token = action.payload.token;
                state.user = action.payload.user;
            })
            .addCase(verifyEmail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(loadUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loadUser.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.user = action.payload;
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.user = null;
                state.error = action.payload;
            })
            .addCase(resendVerification.pending, (state) => {
                state.loading = true;
            })
            .addCase(resendVerification.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(resendVerification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(forgotPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAccount.fulfilled, (state) => {
                state.token = null;
                state.isAuthenticated = false;
                state.loading = false;
                state.user = null;
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(changePassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { logout, clearError, setNeedsVerification } = authSlice.actions;

export default authSlice.reducer;