import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    const date = new Date().getFullYear();
    return (
        <footer className="bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="py-8 text-center text-gray-500">
                    <p>&copy; {date} TracKxpense. All rights reserved.</p>
                    <div className="mt-2">
                        <Link to="/privacy-policy" className="text-gray-400 hover:text-primary mx-2">
                            Privacy Policy
                        </Link>
                        <Link to="/terms-of-service" className="text-gray-400 hover:text-primary mx-2">
                            Terms of Service
                        </Link>
                        <Link to="/contact-us" className="text-gray-400 hover:text-primary mx-2">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;