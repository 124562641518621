import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaHome, FaMoneyBillWave, FaCog, FaBars, FaTimes, FaSignOutAlt } from 'react-icons/fa';
import { logout } from '../features/auth/authSlice';

const NavItems = ({ onItemClick }) => {
    const location = useLocation();
    const navItems = [
        { path: '/dashboard', icon: FaHome, label: 'Dashboard' },
        { path: '/subscriptions', icon: FaMoneyBillWave, label: 'Subscriptions' },
        { path: '/settings', icon: FaCog, label: 'Settings' },
    ];

    return (
        <ul>
            {navItems.map((item) => (
                <li key={item.path} className="mb-4">
                    <Link
                        to={item.path}
                        className={`flex items-center p-2 rounded hover:bg-indigo-500 transition-colors duration-200 ${location.pathname === item.path ? 'bg-indigo-700' : ''}`}
                        onClick={onItemClick}
                    >
                        <item.icon className="mr-2" />
                        {item.label}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const Sidebar = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        if (window.confirm("Are you sure you want to logout?")) {
            dispatch(logout());
            navigate('/');
        }
    };

    return (
        <aside className={`bg-primary text-white w-64 fixed inset-y-0 left-0 z-30 flex flex-col transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
            <div className="flex-grow overflow-y-auto">
                <div className="p-4">
                    <div className="flex justify-between items-center">
                        <Link to="/dashboard" className="text-2xl font-bold text-white hidden lg:block">
                            TracKxpense
                        </Link>
                        <button onClick={onClose} className="text-white lg:hidden">
                            <FaTimes size={24} />
                        </button>
                    </div>
                    <nav className="mt-8">
                        <NavItems onItemClick={onClose} />
                    </nav>
                </div>
            </div>
            <div className="p-4 border-t border-indigo-800">
                <button
                    onClick={handleLogout}
                    className="w-full flex items-center p-2 rounded hover:bg-indigo-500 transition-colors duration-200"
                >
                    <FaSignOutAlt className="mr-2" />
                    Logout
                </button>
            </div>
        </aside>
    );
};

const AuthenticatedLayout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow flex overflow-hidden">
                <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
                <div className="flex-1 flex flex-col w-full lg:ml-64 overflow-hidden">
                    <div className="lg:hidden bg-primary p-4 flex justify-between items-center">
                        <Link to="/dashboard" className="text-2xl font-bold text-white">
                            TracKxpense
                        </Link>
                        <button onClick={() => setIsSidebarOpen(true)} className="text-white">
                            <FaBars size={24} />
                        </button>
                    </div>
                    <main className="flex-grow p-4 overflow-y-auto bg-gray-100">
                        <div className="max-w-full">
                            {children}
                        </div>
                    </main>
                </div>
            </div>
            {isSidebarOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden" onClick={() => setIsSidebarOpen(false)}></div>
            )}
        </div>
    );
};

export default AuthenticatedLayout;